/*! E-sites | e-sites.nl */

/*  =========================================================================
    Settings
    Global variables, config switches.
    ========================================================================= */

@import '00-settings/vars';
@import '~@supple-kit/supple-css/lib/settings/defaults';
@import '~@supple-kit/supple-css/lib/settings/responsive';

/*  =========================================================================
    Tools
    Default mixins and functions.
    ========================================================================= */

@import './node_modules/sass-mq/mq';
@import '~@supple-kit/supple-css/lib/tools/functions';
@import '~@supple-kit/supple-css/lib/tools/mixins';
@import '01-tools/mixins/shared-input';
@import '01-tools/mixins/generic-focus';

/*  =========================================================================
    Generic
    Ground zero styles (normalize.css, box-sizing, resets).
    ========================================================================= */

@import '~@supple-kit/supple-css/lib/generic/reset';
@import '03-elements/page';

/*  =========================================================================
    Elements
    Unclassed HTML element selectors
    ========================================================================= */

@import '03-elements/page';
@import '03-elements/font-face';
@import '03-elements/img';
@import '03-elements/links';
@import '03-elements/rule';
@import '03-elements/label';
@import '03-elements/select';
@import '03-elements/svg';
@import '03-elements/typography';

/*  =========================================================================
    Objects
    This layer is concerned with styling non-cosmetic design patterns.
    Prefixed with `o-`
    ========================================================================= */

@import '~@supple-kit/supple-css/lib/objects/list-clean';
@import '~@supple-kit/supple-css/lib/objects/layout';
@import '~@supple-kit/supple-css/lib/objects/retain';
@import '~@supple-kit/supple-css/lib/objects/flexembed';
@import '04-objects/layout';

/*  =========================================================================
    Components
    This layer contains our recognisable components, chunks of UI.
    Prefixed with `c-`
    ========================================================================= */

@import '05-components/button';
@import '05-components/footer';
@import '05-components/list';
@import '05-components/header';
@import '05-components/card';
@import '05-components/properties';
@import '05-components/search-card';
@import '05-components/input-text';
@import '05-components/control';
@import '05-components/form';
@import '05-components/icon-heading';
@import '05-components/select';
@import '05-components/autocomplete';
@import '05-components/breadcrumbs';
@import '05-components/sidebar-layout';
@import '05-components/job-posting-list';
@import '05-components/pagination';
@import '05-components/combined-form';
@import '05-components/properties';
@import '05-components/company-logo';
@import '05-components/loading';
@import '05-components/hero';
@import '05-components/job-posting-detail';
@import '05-components/employer-page';
@import '05-components/input-text';
@import '05-components/bottom-nav';
@import '05-components/sidebar';
@import '05-components/popup';
@import '05-components/ie-warning';
@import '05-components/dashboard-nav';
@import '05-components/dashboard-links';
@import '05-components/woty';
@import '05-components/usps';
@import '05-components/dialog';
@import '05-components/video';
@import '05-components/nprogress';
@import '05-components/autowhatever';
@import '05-components/content-hero';
@import '05-components/selected-companies';
@import '05-components/subscriptions';
@import '05-components/pagepart-text-image';
@import '05-components/subscription-item';
@import '05-components/features-list';
@import '05-components/search-terms';
@import '05-components/related-company-branches';
@import '05-components/toggle';

/*  =========================================================================
    Utilities
    This layer contains some handy helpers & overrides
    Prefixed with `u-`
    ========================================================================= */

@import '~@supple-kit/supple-css/lib/utilities/fractions';
@import '~@supple-kit/supple-css/lib/utilities/module';
@import '~@supple-kit/supple-css/lib/utilities/spacing';
@import '~@supple-kit/supple-css/lib/utilities/visually-hidden';
@import '~@supple-kit/supple-css/lib/utilities/hidden';
@import '06-utilities/text';
@import '06-utilities/umbrella-link';
@import '06-utilities/flush';
@import '06-utilities/spacing';
@import '06-utilities/pending';
@import '06-utilities/list-check';
@import '06-utilities/display';

html {
  scroll-behavior: smooth;
}
