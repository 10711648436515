/**
 * Search terms
 */

/**
 * ======================================
 * Block
 * ======================================
 */
.c-search-terms {
  padding: $supple-space-base $supple-space-small;
  background-color: $color-gray-100;
  border-radius: $border-radius-base;
  text-align: center;

  @include mq($from: md) {
    padding: $supple-space-large $supple-space-base;
  }
}

/**
 * ======================================
 * Elements
 * ======================================
 */

.c-search-terms__list {
  justify-content: center;
  margin-bottom: -$supple-space-tiny;
}

.c-search-terms__link {
  display: flex;
  align-items: center;
  text-decoration: none;
  color: $color-gray-900;
  padding: $supple-space-tiny $supple-space-small;
  background-color: #fff;
  border-radius: $supple-space-huge;
  margin-bottom: $supple-space-tiny;

  &:hover,
  &:focus {
    text-decoration: underline;
    color: $color-blue-500;
  }
}

.c-search-terms__icon {
  margin-right: $supple-space-tiny;
}

/**
 * ======================================
 * Modifiers
 * ======================================
 */
