@keyframes animate-in {
  from {
    opacity: 0;
    scale: 0;
  }
  to {
    opacity: 1;
    scale: 1;
  }
}

/**
 * ======================================
 * Block
 * ======================================
 */
.c-search-card {
  overflow: visible;
  position: relative;
  padding: $supple-space-base 0;

  &:not(.c-search-card--is-floating):not(.c-search-card--full-width) {
    margin-top: $desktop-top-nav-height;
    transform-style: preserve-3d;
  }

  @include mq($from: md) {
    position: sticky;
    z-index: 100;
    top: 0;
  }
}

/**
 * ======================================
 * Elements
 * ======================================
 */
.c-search-card__title {
  text-align: center;
  padding-bottom: $supple-space-base;
  transition: all $base-animation-duration;
  position: relative;
  transform-style: preserve-3d;
  max-width: 100%;

  &::after {
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    bottom: -1px;
    height: 1px;
    background: rgba($color-white, 0.1);
  }
}

.c-search-card__highlight {
  color: $color-green-500;
  background: $color-white;
  display: inline-block;
  border-radius: $border-radius-base;
  padding: 0.1em $supple-space-tiny 0;
  margin-right: $supple-space-tiny/2;
  line-height: 1.2;
  animation-name: 'animate-in';
  animation-duration: 0.3s;
}

.c-search-card__form {
  margin: 0 auto;
  padding: 0;
  transition: all $base-animation-duration;
  position: relative;
  z-index: 1;
}

.c-search-card__backdrop-container {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.c-search-card__backdrop {
  display: none;
  z-index: 0;
}

/**
 * ======================================
 * Modifiers
 * ======================================
 */
.c-search-card--is-floating,
.c-search-card--full-width {
  margin: 0;
}

.c-search-card--full-width {
  border-radius: 0;

  .c-search-card__backdrop {
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    width: 75%;
    height: auto;
    transform: translate(25%, -15%);
    color: rgba($color-white, 0.05);

    @include mq($from: md) {
      top: 50%;
      width: 40%;
      transform: translate(15%, -50%);
    }
  }
}

.c-search-card--has-misc-button {
  padding-bottom: $supple-space-tiny;
}

.c-search-card--is-floating {
  .c-search-card__backdrop {
    display: none;
  }

  @include mq($from: md) {
    padding: $supple-space-tiny 0;
    border-radius: 0;

    .c-search-card__title {
      @include supple-visually-hidden;
    }
  }

  &:not(.c-search-card--full-width) {
    margin: 0 (-$supple-space-base);

    @include mq($from: xl) {
      margin: 0
        calc(
          (100vw - #{supple-value-to-rem($supple-static-breakpoint)}) / -2 - #{$supple-space-base}
        ) !important;
    }
  }
}
