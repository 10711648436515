/**
 * ======================================
 * Block
 * ======================================
 */

$button-spacing-unit: 1ch;

.c-button {
  $button-shadow-size: 0.1rem; // scales on zoom
  padding: calc((var(--target-base-height) - 1.5em) / 2) $button-spacing-unit *
    2;
  min-height: var(--target-base-height);
  min-width: var(--target-base-height);
  text-align: center;
  line-height: 1.5;
  font-size: $font-size-large;
  font-weight: 800;
  text-decoration: none;
  cursor: pointer;
  border: none;
  border-radius: $border-radius-base;
  background: var(--color-brand-secondary, #{$color-green-500});
  color: var(--color-button-text, #{$color-white});
  box-shadow: inset 0 -#{$button-shadow-size} rgba(0, 0, 0, 0.1);
  display: inline-flex;
  align-items: center;
  justify-content: center;

  &:hover,
  &:focus {
    color: $color-white;
    background: $color-blue-500;
  }

  &:focus,
  &:active {
    box-shadow: inset 0 $button-shadow-size rgba(0, 0, 0, 0.1);
  }

  &:focus {
    @include generic-focus();
  }

  [disabled] > &,
  &:disabled {
    cursor: default;
    background: $color-gray-300;
  }

  .whitelabel-theme & {
    &:not([disabled]):hover,
    &:not([disabled]):focus {
      color: var(--color-button-text, #{$color-white});
      background-color: var(--color-brand);
      filter: brightness(1.25);
    }
  }
}

/**
 * ======================================
 * Elements
 * ======================================
 */

.c-button__inner {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  margin-left: $button-spacing-unit * -1;
  text-align: left;
  pointer-events: none; // prevents weird click event stuff

  > * {
    padding-left: $button-spacing-unit;
  }
}

.c-button__icon-container {
  display: flex;
  align-items: center;
  justify-content: center;
}

.c-button__icon {
  flex: 0 0 auto;
  max-width: 1.5em;
  max-height: 1.5em;
}

.c-button__optional-label-part {
  @include mq($from: md, $until: xl) {
    @include supple-visually-hidden;

    & + span {
      text-transform: capitalize;
    }
  }
}

/**
 * ======================================
 * Modifiers
 * ======================================
 */

.c-button--small {
  font-size: $font-size-base;
  padding: calc((var(--target-small-height) - 1.5em) / 2) $button-spacing-unit *
    2;
  min-height: var(--target-small-height);
}

.c-button--tiny {
  font-size: $font-size-base;
  padding: calc((var(--target-tiny-height) - 1.5em) / 2) $button-spacing-unit *
    2;
  min-height: var(--target-tiny-height);
}

.c-button--full-width {
  display: flex;
  width: 100%;
}

.c-button--alert {
  background: $color-purple-500;

  &:hover,
  &:focus {
    background: $color-purple-700;
  }
}

.c-button--transparent,
.c-button--link,
.c-button--link-text,
.c-button--link-white {
  background: none;
  box-shadow: none;
  color: currentColor;
  color: var(--color-brand, $color-blue-500);
  justify-content: flex-start;

  &:hover,
  &:focus,
  &:active {
    background: none;
    box-shadow: none;
    color: $color-blue-700;
  }

  [disabled] > &,
  &:disabled {
    cursor: default;
    background: none;
    color: $color-gray-300;
  }

  .whitelabel-theme & {
    &:not([disabled]):hover,
    &:not([disabled]):focus {
      background-color: transparent;
    }
  }
}

.c-button--link {
  text-decoration: underline;
  padding-left: 0;
  padding-right: 0;
  font-weight: 400;
}

.c-button--link-white {
  color: $color-white;
  text-decoration: none;
  padding-left: 0;
  padding-right: 0;

  &:hover,
  &:active {
    text-decoration: underline;
    color: $color-white;
  }
}

.c-button--link-text {
  font-weight: inherit;
  font-size: inherit;
}

.c-button--icon-only {
  padding: 0;

  .c-button__icon {
    width: calc(var(--target-base-height) / 2);
    height: calc(var(--target-base-height) / 2);
  }
}

.c-button--icon-small {
  .c-button__icon {
    width: $supple-space-small;
    height: $supple-space-small;
  }
}

.c-button--chevron {
  .c-button__icon {
    width: 1ex;
    height: 1ex;
    margin-top: calc((1em - 1ex) / 2);
  }
}

.c-button--hover-white {
  &:hover,
  &:focus {
    color: $color-green-500;
    background: $color-white;
  }
}

.c-button--blue {
  background: $color-blue-500;

  &:hover,
  &:focus {
    background: $color-green-500;
  }
}

.c-button--red {
  color: $color-red-500;
}

.c-button--error {
  background: $color-red-500;

  &:hover,
  &:focus {
    background: $color-white;
    color: $color-red-500;
  }
}

.c-button--file-upload-cta {
  .c-button__label {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    max-width: 250px;
  }
}
