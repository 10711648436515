/**
 * ======================================
 * Block
 * ======================================
 */
$desktop-top-nav-height: calc(
  var(--target-tiny-height) + #{2 * $supple-space-tiny}
);

:root {
  --header-height: 90px; // easier to access via JS, if need be
  --top-nav-height: 0px;

  @include mq($from: lg) {
    --top-nav-height: #{$desktop-top-nav-height};
    --header-height: calc(
      var(--target-base-height) + #{2 * $supple-space-base}
    );
  }
}

.c-header {
  position: sticky;
  z-index: 1000;
  background: $color-white;

  // secondary nav background color
  @include mq($from: lg) {
    &::before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: var(--top-nav-height);
      background: $color-gray-100;
    }
  }

  // best way to allow the box shadow to also overlay menu on mobile
  &::after {
    content: '';
    position: absolute;
    top: 100%;
    left: 0;
    right: 0;
    height: 12px;
    z-index: 2;
    box-shadow: inset 0 12px 10px -10px rgba(0, 0, 0, 0.07);
  }
}

/**
 * ======================================
 * Elements
 * ======================================
 */

.c-header__wrapper {
  --col-width: #{$a11y-size};
  display: grid;
  grid-template: var(--top-nav-height) var(--header-height) / var(--col-width) 1fr var(
      --col-width
    );
  place-items: center;
  gap: 0 $supple-space-base;

  @include mq($from: lg) {
    --col-width: 197px; // logo size
  }
}

/*
  Main navigation wrapper
 */
.c-header__nav-container {
  grid-area: 1/2/-1/-1;
  place-self: stretch;

  // no subgrid yet, so let's get hackin'
  display: grid;
  grid-template: var(--top-nav-height) var(--header-height) / 1fr auto;
  gap: 0 $supple-space-base;
  place-items: center;

  @include mq($until: lg) {
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    display: block;
    background: $color-white;
    max-height: calc(100vh - var(--header-height));
    overflow: auto;
    transition: all $base-animation-duration $base-animation-timing;
    padding: $supple-space-base;
    padding-bottom: $supple-space-tiny;
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1);

    &:not(.c-header__nav-container--open) {
      transform: translateX(-100%);
      box-shadow: 0 0 20px rgba(0, 0, 0, 0);
    }
  }
}

/*
  Main menu
 */
.c-header__main-nav {
  grid-area: 2/1/-1/2;
  align-self: center;
}

.c-header__main-nav-items {
  margin-bottom: 0;
}

/*
  Login menu
 */
.c-header__login-nav {
  position: relative;
  z-index: 1001;
  grid-area: 2/-2/-1/-1;
  overflow: hidden;

  &:hover,
  &:focus-within {
    overflow: visible;
  }
}

.c-header__login-toggle {
  @include mq($until: lg) {
    display: none;
  }
}

.c-header__login-nav-items {
  margin-bottom: 0;

  @include mq($from: lg) {
    display: block;
    position: absolute;
    width: 15rem;
    top: 100%;
    right: 0;
    background: $color-white;
    padding: $supple-space-tiny $supple-space-base;
    border-radius: $border-radius-base;
    box-shadow: $box-shadow-base;
  }
}

.c-header__login-item {
  &:first-child {
    padding-top: 0;
  }

  &:last-child {
    padding-bottom: 0;
  }

  @include mq($from: lg) {
    border-bottom: 1px dotted $color-gray-300;

    &:last-child {
      border-bottom: none;
    }
  }
}

/*
  Secondary menu (a.k.a. one tiny link)
 */
.c-header__secondary-nav {
  position: relative;
  grid-area: 1/1/2/-1;
  place-self: end;
  align-self: center;

  @include mq($from: lg) {
    padding-right: 1px; // no joke, without this alignment just seems off
  }
}

.c-header__menu-toggle {
  grid-area: 2/1/3/2;
  padding-left: 0;
  padding-right: 0;

  .c-button__icon {
    min-width: 1em;
    min-height: 1em;
  }

  @include mq($from: lg) {
    display: none;
  }
}

.c-header__logo-link {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  vertical-align: middle;
  min-height: var(--target-base-height);
  max-width: 100%;
  grid-area: 2/2/3/3;

  @include mq($from: lg) {
    grid-area: 2/1/3/2;
  }
}

.c-header__link {
  font-weight: 400;
  padding-left: 0;
  padding-right: 0;

  @include mq($until: lg) {
    justify-content: flex-start;
    border-radius: 0;
    border-bottom: 1px dotted $color-gray-300;
    width: 100%;
  }

  @include mq($from: lg) {
    font-weight: 700;
  }
}

/**
 * ======================================
 * Modifiers
 * ======================================
 */

.c-header__link--secondary,
.c-header__link--login {
  font-size: $font-size-base;
  font-weight: 400;
  text-decoration: none;
}

.c-header__link--secondary {
  @include mq($until: lg) {
    font-size: $font-size-large;
    justify-content: flex-start;
    padding: calc((var(--target-base-height) - 1.5em) / 2) 0;
  }
}

.c-header__link--login {
  white-space: nowrap;
  width: 100%;
}
