/**
 * Selected companies
 */

/**
 * ======================================
 * Block
 * ======================================
 */

.c-selected-companies {
  margin-top: 6rem;
  margin-bottom: 6rem;
}

/**
 * ======================================
 * Elements
 * ======================================
 */

.c-selected-companies__title {
  line-height: 3rem;
  text-align: center;
}

.c-selected-companies__list {
  margin-top: 3rem;
  padding-left: 0px;
  list-style: none;
  display: grid;
  gap: 1rem;
  grid-template-columns: repeat(2, 1fr);

  @include mq($from: sm) {
    grid-template-columns: repeat(3, 1fr);
  }

  @include mq($from: xl) {
    grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
  }
}

.c-selected-companies__list-item {
  display: flex;
  align-items: center;
  justify-content: center;
}

.c-selected-companiers__marked-text {
  color: $color-white;
  background-color: $color-blue-500;
  border-radius: 3px;
  padding-left: 5px;
  padding-right: 5px;
  font-size: $font-size-large;
  font-size: $font-size-h1;
}

/**
 * ======================================
 * Modifiers
 * ======================================
 */
