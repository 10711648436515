/**
 * ======================================
 * Block
 * ======================================
 */

.c-sidebar-layout {
  position: relative;

  @media (min-width: $sidebar-width  * 3) {
    display: grid;
    gap: $supple-space-large;
    grid-template-columns: 1fr $sidebar-width;
  }
}

/**
 * ======================================
 * Element
 * ======================================
 */

.c-sidebar-layout__sidebar {
  position: sticky;
  bottom: 0;
}

/**
 * ======================================
 * Modifiers
 * ======================================
 */

.c-sidebar-layout--inverse {
  @media (min-width: $sidebar-width  * 3) {
    grid-template-columns: $sidebar-width 1fr;
  }
}
