/**
 * ======================================
 * Block
 * ======================================
 */
.c-footer {
  position: relative;
  margin-top: $supple-space-large;
  padding: $supple-space-large * 1.5 0 $supple-space-base;
  background: $color-blue-500;
  color: $color-white;
  overflow: hidden;

  a {
    color: inherit;
    text-decoration: none;

    &:hover,
    &:focus {
      text-decoration: underline;
    }
  }

  .whitelabel-theme & {
    background: $color-gray-100;
    color: $color-blue-500;
    padding: 0;
  }
}

/**
 * ======================================
 * Elements
 * ======================================
 */
.c-footer__backdrop {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 0;
  width: 450px;
  height: auto;
  transform: translate(-25%, -15%);
  color: rgba($color-white, 0.05);

  .whitelabel-theme & {
    display: none;
  }

  @include mq($from: md) {
    top: 50%;
    height: 150%;
    width: auto;
    transform: translate(-35%, -50%);
  }
}
.c-footer__content {
  position: relative;
  z-index: 1;
}

.c-footer__title {
  font-size: 1rem;
  margin-bottom: $supple-space-base;
}

.c-footer__list-item {
  margin-bottom: $supple-space-tiny;
}

.c-footer__social {
  &:hover,
  &:focus {
    color: $color-blue-200;
  }
}

.c-footer__bottom {
  padding: $supple-space-base 0 $supple-space-small;
  text-align: center;
  border-top: 1px solid rgba($color-white, 0.1);

  @include mq($until: md) {
    margin: 0 (-$supple-space-base);
  }
}
