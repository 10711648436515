/**
 * Place vacancy - hero
 */

/**
 * ======================================
 * Block
 * ======================================
 */

/**
 * ======================================
 * Elements
 * ======================================
 */
.c-content-hero__text {
  max-width: 28rem;
}

.c-content-hero__marked-text {
  color: $color-white;
  background-color: $color-blue-500;
  border-radius: 3px;
  padding-left: 2px;
  padding-right: 2px;
  font-size: $font-size-large;
}

.c-content-hero__contact-section {
  display: flex;
  flex-direction: column;
}

.c-content-hero__image {
  box-shadow: $box-shadow-base;
  span {
    display: block !important;
  }
}

/**
 * ======================================
 * Modifiers
 * ======================================
 */
